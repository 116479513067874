@import '@/styles/colors.module';
@import "@/styles/mixins";

.countIndex {
  color: $dark_gray;
}

.rowExpanded {
  background: #eff2f5;
  padding-right: 0;

  :global {
    .ant-table-cell-row-hover {
      background: #eff2f5 !important;
    }
  }
}

.addBtn {
  padding: 0 !important;
  font-weight: 500;
  display: flex !important;
  align-items: center;
  color: $blue;
  width: max-content;
}

.variationGreen {
  color: $green;
  text-align: right;
  white-space: nowrap;
}

.variationRed {
  color: $red;
  text-align: right;
  white-space: nowrap;
}

.averagePrice {
  text-align: right;
  white-space: nowrap;
}

.nmckPrice {
  text-align: right;
  white-space: nowrap;
}

.hiddenCell {
  position: static !important;
}

.col {
  &Name {
    min-width: 140px !important;
  }

  &OkpdKtru {
    min-width: 170px !important;
  }

  &Price {
    min-width: 160px !important;
  }

  &UnitMeasure {
    min-width: 100px;
  }

  &Amount {
    min-width: 75px;
    max-width: 110px;
  }

  &Nkmi {
    min-width: 90px;
  }
}