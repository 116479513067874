@import '@/styles/colors.module';

.show {
  cursor: pointer;
  color: $blue;
}

.showActive {
  opacity: 0.5;
}

.viconActive {
  transform: rotate(-180deg);
}

.toooltipText {
  color: $light_gray;
}

.check {
  &Applied {
    color: $green;
  }

  &AppliedWithSelection {
    color: $blue_2;
  }
}

.rejectWithSelection {
  color: $gold;
}
