.withChildren {
  &Overlay {
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.5);
  }

  &Spinner {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}