@import '@/styles/colors.module';
.wrapper {
  :global {
    .ant-table-wrapper .ant-table-cell {
      &:first-child {
        padding-left: 32px !important;
      }
    }
    .ant-table-thead tr .ant-table-cell {
      background: $white;
      color: $light_blue;
      font-weight: 500;
      padding-left: 16px !important;
    }
    .ant-table-row {
      vertical-align: top;

      &:last-child {
        font-weight: 600;
      }
    }
    .ant-table {
      border-radius: 8px 8px 0 0 !important;
    }
  }
  .table {
    font-weight: 400;

    .name,
    .nmck,
    .purchaseType,
    .result,
    .supplier {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .name,
    .supplier {
      max-width: 320px;
    }
    .nmck,
    .contractPrice,
    .plannedThisYear,
    .paid,
    .supplied,
    .paidThisYear,
    .remainingAmountToPay,
    .economy {
      text-align: end;
    }
    .purchaseType {
      width: 153px;
    }
    .smp {
      width: 106px;
    }
    .contractReestrNumber,
    .notificationNumber {
      color: $blue !important;
      display: block;
      width: max-content;
    }
    .nmck,
    .contractPrice {
      width: 130px;
    }
    .plannedThisYear {
      min-width: 208px;
    }
    .paid {
      min-width: 214px;
    }
    .supplied {
      min-width: 230px;
    }
    .paidThisYear {
      min-width: 253px;
    }
    .remainingAmountToPay {
      min-width: 152px;
    }
    .economy {
      min-width: 90px;
    }
    .executionDate {
      min-width: 124px;
    }
    .result {
      width: 156px;
    }
    .drugs {
      min-width: 190px;
    }
    .medDevices {
      min-width: 96px;
    }
    .contractNumber {
      min-width: 122px;
      width: max-content;
    }
  }
}
