@import '@/styles/colors.module';

.select,
.input {
  width: 288px !important;
  height: 40px !important;
}

.doubleInput,
.datePicker {
  width: 136px !important;
  height: 40px !important;
}
