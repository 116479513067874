@import '@/styles/colors.module';
.modal {
  :global {
    .ant-modal-content {
      padding: 0 0 !important;

      .ant-modal-header {
        border-radius: 16px !important;
        padding: 45px 64px;
        margin: 0;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }
      .ant-modal-footer {
        border-top: 1px solid $border_gray;
        margin: 0;
        padding: 28px 64px;
        background-color: $gray;
        border-radius: 0 0 16px 16px !important;
        text-align: start;
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .ant-checkbox + span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
      .ant-modal-close {
        top: 48px;
        right: 64px;
      }
      .ant-modal-close-x {
        font-size: 24px;
      }
    }
  }

  .content {
    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .text {
        color: $light_gray;
        font-weight: 500;
        display: flex;
        gap: 10px;
        .name {
          width: 220px;
        }
        .type {
          width: 180px;
        }
        .value {
          width: 520px;
        }
      }
      .addCharItemButton {
        height: 40px;
        width: 125px;
        display: flex;
        align-items: center;
        color: $blue;
        border-color: $blue;
        background: transparent;
      }
    }
  }
  .cancelBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: transparent;
    border: 1px solid $border_gray;
    color: $light_gray;
  }
  .submitBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: $light_blue;
    color: $white;
  }
}
