@import '@/styles/colors.module';

.wrapper {
  :global {
    .ant-table-wrapper .ant-table-cell {
      &:first-child {
        padding-left: 32px !important;
      }
    }

    .ant-table-thead tr .ant-table-cell {
      background: $white;
      color: $light_blue;
      font-weight: 500;
      padding-left: 16px !important;
    }

    .ant-table-row {
      vertical-align: top;
    }

    .ant-table {
      border-radius: 1px 1px 1px 1px !important;
    }
  }

  .table {
    font-weight: 400;

    .name {
      width: 300px;
    }

    .amount {
      width: 110px;
    }

    .okpdCode {
      margin-top: 7px;
      cursor: pointer;
      color: $blue;
      border-bottom: 1px dashed $blue;
      white-space: nowrap;
      width: fit-content;
    }

    .chars {
      .charItem {
        line-height: 18px;
        display: flex;
        gap: 5px;
        flex-wrap: nowrap;

        .charName {
          color: $light_gray;
        }
      }

      .addChars,
      .editChars {
        color: $blue;
        width: 118px;
        border-color: $blue;
        background: transparent;
      }

      .editChars {
        margin-top: 8px;
      }
    }

    :global {
      .ant-table-tbody {
        .ant-table-selection-column {
          .ant-checkbox-wrapper {
            padding-top: 9px;
          }
        }
      }
    }
  }
}
