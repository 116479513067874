@import '@/styles/colors.module';

.wrapper {
  :global {
    .ant-table-wrapper .ant-table-cell {
      &:first-child {
        padding-left: 32px !important;
      }
    }

    .ant-table-thead tr .ant-table-cell {
      background: $white;
      color: $light_gray;
      font-weight: 500;
      padding-left: 16px !important;
    }

    .ant-table-row {
      vertical-align: top;

      &:last-child {
        font-weight: 600;
      }
    }

    .ant-table {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  .table {
    font-weight: 400;

    .itemName,
    .supplier,
    .cause {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 160px;
    }

    .commentInput {
      height: 22px;
      width: 200px;
      padding: 0;
      border-radius: 0;
      color: $black;
      border-bottom: 1px solid $border_gray;

      &::placeholder {
        color: $light_gray;
        opacity: 0.5;
      }
    }

    .copy,
    .edit,
    .delete {
      color: $border_gray;
    }

    .row {
      cursor: pointer;

      &:hover {
        .copy,
        .edit {
          color: $blue;
        }

        .delete {
          color: $red;
        }
      }
    }
  }
}
