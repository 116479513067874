@import '@/styles/colors.module';

.contractsNotFound {
  display: flex;
  padding: 16px;
  width: 100%;
  background: #e1eeff;
  color: $dark_gray;

  .spin {
    margin: 0 auto;
  }
}
