@import "@/styles/colors.module";

.label {
  min-width: 150px;
  color: $light_gray;
}

.colon {
  display: inline-block;
  margin-left: 1px;
}

.hint {
  margin-left: 8px;
  height: 16px;
}