@import '@/styles/colors.module';
.modal {
  .modalHeader {
    padding: 0 24px;
    display: flex;
    align-items: center;
    background: $border_gray;
    margin-top: -20px;
    margin-left: -24px;
    margin-right: -24px;
    border-radius: 8px 8px 0 0;
    height: 54px;
  }
  .btnsWrappes {
    margin-right: 40px;
    .btn {
      display: flex;
      align-items: center;
    }
  }
}
