@import "@/styles/colors.module";

.label {
  color: $light_gray;
  font-weight: 500;
}

.value {
  color: $blue;
  font-weight: 500;
}

.select {
  :global {
    .ant-select-selector {
      padding-left: 0 !important;
    }
  }
}
