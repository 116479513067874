@import '@/styles/colors.module';

.button {
  padding: 0 !important;
  border-radius: 0 !important;

  span {
    border-bottom: 1px dashed $blue !important;

  }

  &[disabled] {
    span {
      border-bottom: none !important;
    }
  }
}

.buttonsWrap {
  column-gap: 16px;
  row-gap: 4px;
}

.datePicker {
  width: 140px;
}
