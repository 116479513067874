@import "@/styles/mixins";
@import "@/styles/colors.module";

.supplier {
  @include truncate;
  min-width: 0;
  font-size: 12px;
  color: $light_blue;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}
