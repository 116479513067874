@import '@/styles/colors.module';

.wrapper {
  background-color: white;

  .header {
    padding: 46px 64px;
    overflow: hidden;

    .choosePeriod {
      .period {
        color: $blue;
        cursor: pointer;
        border-bottom: 1px dashed $blue;
      }

      .periodDisabled {
        color: $blue;
        opacity: 0.6;
      }
    }
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .main {
    padding: 32px 54px 32px 32px;
    background-color: $border_light_gray;
    height: 100%;
  }
}
