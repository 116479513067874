@import '@/styles/colors.module';

.wrapper {
  padding: 32px 32px;
  line-height: 22px;
  font-weight: 500;
  font-size: 14px;

  .settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px;
    background: $white;

    .pagination {
      display: flex;
      align-items: center;
    }

    .sorting {
      color: $light_blue;

      :global {
        .ant-select-selection-item {
          color: $light_blue;
        }
      }

      .iconV {
        display: flex;
        color: $light_blue;
      }
    }
  }
}
