@import '@/styles/colors.module';

.wrapper {
  padding: 20px 64px;
  border-left: 1px solid $border_gray;
  display: flex;
  gap: 12px;
  flex-direction: column;
  color: $light_gray;
  height: fit-content;

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .formula {
    color: $black;
    line-height: 22px;
  }

  .hint {
    font-size: 14px;
    line-height: 26.5px;
  }
}
