@import '@/styles/colors.module';

.wrapper {
  padding: 32px 32px;
  line-height: 22px;
  font-weight: 500;
  font-size: 14px;

  .settings {
    padding: 32px 32px;
    background: $white;
  }
}
