@import '@/styles/colors.module';

.wrapper {
  padding: 46px 64px;
  background-color: $white;

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .main {
    padding: 46px 0;
  }
}
