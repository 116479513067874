@import '@/styles/colors.module';

.wrapper {
  :global {
    .ant-tabs-nav::before {
      border-bottom: 1px solid $border_gray !important;
    }
  }

  background: $gray;
  padding: 15px 64px 30px;

  .tabs {
    font-weight: 500;

    :global {
      .ant-tabs-tab.ant-tabs-tab-disabled {
        cursor: default;
      }

      .ant-tabs-nav {
        margin: 0 0 40px 0;
      }
    }

    .tabItem {
      font-size: 16px;

      .crossButton {
        &:not(:disabled):hover {
          color: $red;
        }
      }
    }
  }
}
