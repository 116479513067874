@import '@/styles/colors.module';

.table {
  &WithVerticalScroll {
    max-height: 600px;
    overflow-y: auto;
  }

  th {
    padding: 10px 5px 16px;
  }

  :global {
    .ant-table-expanded-row {
      .ant-table-cell {
        background-color: white;
      }
    }

    .ant-table-thead {
      tr:first-child {
        th:first-child {
          padding-left: 32px;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td:first-child {
          padding-left: 32px;
        }
      }
    }
  }
}

.purchaseNumber {
  color: $light_gray;
  min-width: 74px;
}

.calculationMethod {
  min-width: 160px;
}

.code {
  min-width: 113px;
}

.name {
  font-weight: 500;
  min-width: 110px;
}

.columnTitles {
  display: flex;
  text-align: center;
  white-space: nowrap;
}

.columnTitle {
  padding: 0;
  text-align: left;
  white-space: nowrap;
}

.expandableContent {
  width: 1200px;
  padding-left: 32px;

  .acceptButton,
  .rejectButton {
    display: flex;
    align-items: center;
  }

  .acceptButton {
    border-color: $blue_2;
    color: $blue_2;
  }

  .rejectButton {
    border-color: $gold;
    color: $gold;
  }
}

.expandableText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $light_gray;
}

.parrentRow {
  padding: 22px 10px 0 !important;
  text-align: left !important;
  border-bottom: none !important;
}

.childRow {
  padding: 0 10px 6px !important;
}
