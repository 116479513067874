@import '@/styles/colors.module';

.wrapper {
  width: 908px;

  .postNameInput {
    width: 240px !important;
  }

  .select {
    width: 240px;
    height: 40px !important;
  }

  .label {
    white-space: nowrap;
    color: $light_gray;
  }

  .inputNumber {
    width: 80px;
  }
}


