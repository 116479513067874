$unitMeasureDropdownWidth: 240px;
:export {
  unitMeasureDropdownWidth: $unitMeasureDropdownWidth;
}
.units {
  .select {
    height: 40px;
    width: 120px;
  }
}
