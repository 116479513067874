@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  line-height: 22px;
  background-color: $white;

  .flex {
    display: flex;
    gap: 32px;
    flex-direction: column;

    .title {
      min-width: 313px;
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }

    .expand {
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      cursor: pointer;

      &.active {
        opacity: 0.4;
      }
    }

    .expandIcon {
      transition: transform 0.25s linear;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }
}
