@import '@/styles/colors.module';

.wrapper {
  padding: 20px 32px;
  background-color: $border_light_gray;
  border-radius: 8px;
  width: 396px;
  height: 266px;

  .main {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
    justify-content: space-between;
    column-gap: 16px;
    row-gap: 10px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: $light_gray;
    margin-bottom: 16px;
  }

  .text {
    grid-column-start: 1;
    font-size: 14px;
    line-height: 22px;
    color: $light_gray;
    white-space: nowrap;
  }
  .amount {
    font-size: 16px;
    line-height: 22px;
  }
}
