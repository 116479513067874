@import '@/styles/colors.module';

.additionalServices {
  padding: 10px 64px 32px 64px;
  line-height: 20px;
  font-weight: 500;
  background-color: $white;

  .antDivider {
    margin: 0;
    border-block-start: 1px solid $border_gray;
  }
}
