@import '@/styles/colors.module';
.radios {
  display: flex;
  gap: 64px;
  align-items: center;
  color: $light_gray;
  font-weight: 500;
  font-size: 14px;
  :global {
    .ant-radio + span {
      font-weight: 400;
      color: $light_gray;
    }
    .ant-radio-checked + span {
      font-weight: 500;
      color: $black;
    }
  }
  .status {
    width: 401px;
  }
  .status,
  .concluded {
    display: flex;
    gap: 16px;
  }
}
