@import '@/styles/colors.module';

.autocompleteName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $light_blue;
}

.autocompleteWrapper {
  height: 40px !important;
}

.input {
  &:before {
    display: none !important;
  }

  input {
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}
