@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  line-height: 22px;
  background-color: $white;

  .flex {
    display: flex;
    gap: 32px;
    flex-direction: column;

    .title {
      min-width: 313px;
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }

    .searchInput {
      width: 540px;

      .crossIcon {
        display: flex;
        align-items: center;
        color: $light_gray;
      }
    }

    .expandButton {
      &.active {
        opacity: 0.6;
      }
    }

    .expandIcon {
      transition: transform 0.25s linear;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }
}
