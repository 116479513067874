@import '@/styles/colors.module';

.wrapper {
  display: flex;
  gap: 32px;

  .main {
    display: flex;
    gap: 4px;
    flex-direction: column;
    width: min-content;
  }

  .chart {
    min-width: 510px;
    max-width: 640px;
    display: flex;
    gap: 40px;
    align-items: center;
    background-color: $white;
    padding: 86px 66px 86px 56px;
    border-radius: 8px;
    width: fit-content;

    .list {
      .listItem:last-child {
        font-size: 16px;
        font-weight: 500;
        margin-top: 14px;
        .point {
          display: none;
        }
      }
    }

    .listItem {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      width: max-content;
      align-items: center;
      gap: 10px;
    }
  }

  .point {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .doughnut {
    width: 240px;
    height: 240px;
  }
}
