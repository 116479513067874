.wrapper {
  position: relative;

  .zone {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    transition: background-color 0.3s;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.05);

    &.zoneLeft {
      left: 0;
    }

    &.zoneRight {
      right: 0;
    }

    &.zoneActive {
      background-color: rgba(0, 0, 0, 0.2)
    }

    &.zoneInactive {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
}