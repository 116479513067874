@import '@/styles/colors.module';

.characteristics {
  max-width: 200px;

  .characteristicName {
    font-size: 12px;
    color: $light_gray;
  }

  .characteristicValue {
    max-width: 126px;
  }

  .showButton {
    width: fit-content !important;
    line-height: 18px !important;
    height: 18px !important;
  }
}