.button {
  position: absolute !important;
  left: 443px;
  bottom: 6px;

  .vIcon {
    color: unset;
    
    &Expanded {
      transform: rotate(-180deg);
    }
  }
}