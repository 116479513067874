@import '@/styles/colors.module';

.autocomplete {
  width: 100%;
  height: 40px !important;

  .dotsIcon {
    cursor: pointer;
    color: $light_gray;
  }

  .input {
    height: 40px !important;
  }
}

.optionName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $light_blue;
}
