@import '@/styles/colors.module';

.container {
  width: calc(100vw - 25px);
}

.divider {
  max-width: 100%;
  margin: 0 32px;
}
