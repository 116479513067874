@import '@/styles/colors.module';

.label {
  color: $light_gray;
  font-weight: 400;
  font-size: 14px;

  &.active {
    color: $black;
    font-weight: 500;
  }
}
