@import '@/styles/colors.module';

.title {
  font-size: 20px;
}

.label {
  font-size: 14px;
  color: $light_gray;
}

.datepicker {
  height: 40px !important;
}