@import '@/styles/colors.module';

.wrapper {
  padding: 0 60px;

  .title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .search {
    width: 715px;
  }

  .toggleShownButton {
    display: flex !important;
    align-items: center;
  }
}

.characteristicsSelect {
  width: 230px !important;
}