@import '@/styles/colors.module';

.wrapper {
  background-color: $white;

  :global {
    .ant-tabs-nav::before {
      border-bottom: none !important;
    }
  }

  padding: 34px 64px;

  .title {
    max-width: fit-content;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .tag {
    margin-inline-end: 0;
    max-width: max-content;
  }

  .charachteristics {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $light_gray;
    width: 258px;
  }

  .tabs {
    font-weight: 500;
    line-height: 20px;
  }
}

.contentWrapper {
  background: $gray;
  padding: 32px;
}
