@import '@/styles/colors.module';

.wrapper {
  white-space: nowrap;
}

.question {
  z-index: 5;
  color: $light_blue;
}
