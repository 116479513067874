@import '@/styles/colors.module';

.modal {
  :global {
    .ant-modal-content {
      padding: 0 !important;
    }
  }

  .wrapper {
    .button {
      display: flex;
      align-items: center;
    }

    .header {
      padding: 34px 64px;
    }

    .title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }

    .excel {
      cursor: pointer;
      color: $blue;
    }

    .exportIcon {
      display: flex;
      min-width: fit-content;
    }

    .main {
      border-radius: 8px;
      padding: 16px 32px 32px;
      background-color: $border_light_gray;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .filterWrapper {
        margin-top: 20px;
      }
    }
  }
}
