@import '@/styles/colors.module';

.popUpContent {
  .popUpText {
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }
}
