@import '@/styles/colors.module';

.guardHeader {
  padding: 48px 64px;
  background-color: $white;

  .title {
    min-width: 250px;
    width: 250px;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

}
