@import '@/styles/colors.module';
@import "@/styles/mixins";

.wrapper {
  width: 100%;
  padding: 28px 64px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background-color: $white;
  @include sticky-element();
}

.output {
  gap: 24px;
}
