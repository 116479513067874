@import '@/styles/colors.module';
.wrapper {
  :global {
    .ant-table-wrapper .ant-table-cell {
      &:first-child {
        padding-left: 32px !important;
      }
    }
    .ant-table-thead tr .ant-table-cell {
      background: $white;
      color: $light_blue;
      font-weight: 500;
      padding-left: 16px !important;
    }
    .ant-table-row {
      vertical-align: top;
    }
    .ant-table {
      border-radius: 1px 1px 1px 1px !important;
    }
  }
  .table {
    font-weight: 400;

    .name,
    .responsible {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 400px;
    }
    .responsible {
      width: 230px;
    }
    .amount {
      max-width: max-content;
    }
    .number {
      max-width: 122px;
    }
    .tag {
      margin-inline-end: 0;
      max-width: max-content;
    }
    .row {
      cursor: pointer;
    }
  }
}
