.input {
  padding-right: 6px !important;

  input {
    min-width: 70px;
  }

  &:before {
    display: none !important;
  }
}
