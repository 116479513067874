@import '@/styles/colors.module';

.wrapper {
  padding-right: 64px;
  .calc {
    .btn {
      display: flex;
      align-items: center;
      color: $white;
      background: $light_blue;
      padding: 8px 16px;
      height: 40px;
    }

    .btnDisabled {
      opacity: 0.4;
    }

    .buttons,
    .field {
      padding: 12px 0;
    }

    .rate {
      padding: 22px 0;
    }

    .cbHref {
      display: flex;
    }

    .field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 39px;
      white-space: nowrap;
      font-size: 14px;
      line-height: 22px;
      color: $light_gray;

      .input {
        width: 300px;
        height: 40px;
        font-size: 14px;
        color: $black;

        &::placeholder {
          color: $light_gray;
          opacity: 0.5;
        }
      }

      .dates {
        align-items: center;

        .dateText {
          font-size: 14px;
          font-weight: 500;
          color: $light_gray;
        }

        .date {
          width: 140px;
          height: 40px;
        }
      }
    }

    .clear {
      transition: all ease 0.25s;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: $blue;
    }

    .clearDisabled {
      transition: all ease 0.25s;
      color: $black;
      opacity: 0.25;
    }

    .clearButton {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }
}
