@import '@/styles/colors.module';

.listItem {
  background: $white;
  border-radius: 8px;
  padding: 22px 30px;
  margin-bottom: 4px;

  .grid {
    display: grid;
    align-items: baseline;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 458px 380px 190px 140px 20px;
    gap: 0 20px;
    padding: 0 32px;
    font-weight: 400;
    font-size: 14px;

    .name {
      font-size: 16px;
    }

    .select,
    .datePicker {
      height: 40px;
    }

    .select {
      width: 180px;
    }

    .datePicker,
    .dealineInput {
      width: 130px;
    }

    .crossIcon {
      display: flex;
      align-items: center;
    }
  }
}
