@import "@/styles/colors.module";

.tag {
  padding: 6px 13px;
  background-color: $border_gray;
  color: $light_gray;
  border: 1px solid $input_border_gray;
  border-radius: 20px;
  height: fit-content;
  font-size: 14px;
  line-height: 10px;
}