@import '@/styles/colors.module';

.stage {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .title {
    padding: 0 32px;
    display: flex;
    align-items: baseline;
    gap: 24px;
    height: 30px;

    .stageName {
      font-size: 20px;
      font-weight: 500;
    }

    .endDate {
      font-size: 14px;
      color: $light_gray;
    }
  }

  .grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 478px 400px 210px 160px 40px;
    padding: 0 32px;
    font-weight: 500;
    font-size: 16px;
  }
}
