@import '@/styles/colors.module';

.wrapper {
  display: flex;
  gap: 21px;
  background-color: $white;
  padding: 24px 22px;
  border-radius: 8px;
  align-items: center;
  height: 68px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  .sum {
    white-space: nowrap;
  }
}
