@mixin sticky-element($position: bottom, $z-index: 10) {
  position: sticky;
  #{$position}: 0;
  z-index: $z-index;
}

@mixin truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}