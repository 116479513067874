@import '@/styles/colors.module';

.listItem {
  background: $white;
  border-radius: 8px;
  padding: 0 30px !important;

  .grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 460px 146px 206px 1fr;
    gap: 0 20px;
    font-weight: 400;
    font-size: 14px;

    .select,
    .datePicker {
      height: 40px;
    }

    .select {
      width: 180px;
    }

    .datePicker {
      width: 130px;
      margin: 13px 0;
    }

    .stage {
      color: $light_blue;
      font-size: 16px;
      font-weight: 500;
      margin: 22px 0;

      :global {
        .ant-typography-copy {
          svg,
          span {
            top: 2px;
          }
        }
      }
    }

    .law {
      color: $light_gray;
      max-width: 701px;
      margin: 22px 0;
      line-height: 18px;
    }

    .copyIcon {
      color: $light_blue;

      &:hover {
        color: $blue;
      }
    }

    .dates {
      display: flex;
      gap: 4px;
      margin: 22px 0;

      .date {
        width: fit-content;
        cursor: pointer;
        height: fit-content;

        &:hover {
          border-radius: 4px;
          background: $gray;
        }
      }

      .dateHidden {
        visibility: hidden;
      }
    }

    .error {
      color: $red;
    }
  }
}
