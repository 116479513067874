@import '@/styles/colors.module';
.modal {
  :global {
    .ant-modal-content {
      padding: 0 0 !important;

      .ant-modal-header {
        border-radius: 16px !important;
        padding: 45px 64px;
        margin: 0;

        .ant-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
      }
      .ant-modal-footer {
        border-top: 1px solid $border_gray;
        margin: 0;
        padding: 28px 64px;
        background-color: $gray;
        border-radius: 0 0 16px 16px !important;
        text-align: start;
      }

      .ant-modal-body {
        background-color: $gray;
        padding: 32px 64px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .ant-checkbox + span {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
      .ant-modal-close {
        top: 48px;
        right: 64px;
      }
      .ant-modal-close-x {
        font-size: 24px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 1166px;
    .input,
    .select,
    .datePicker {
      height: 40px;
      width: 140px;
    }
    .inputLong {
      width: 420px;
    }
    .lastItem {
      align-self: flex-end;
    }
    .text,
    .textLong {
      width: 140px;
      flex-shrink: 0;
      font-weight: 400;
      color: $light_gray;
      font-size: 14px;
    }
    .textLong {
      width: 270px;
    }
  }
  .cancelBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: transparent;
    border: 1px solid $border_gray;
    color: $light_gray;
  }
  .submitBtn {
    width: 122px;
    height: 40px;
    border-radius: 4px;
    padding: 5px 16px;
    background: $light_blue;
    color: $white;
  }
}
