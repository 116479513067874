@import '@/styles/colors.module';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 620px;

  .sgoz {
    display: flex;
    flex-direction: column;
    gap: 1px;

    .row,
    .subrow,
    .requestSubrow {
      display: flex;
      align-items: center;
      height: 68px;
      padding: 0 90px 0 32px;
      border-radius: 8px 8px 0 0;
      background-color: $white;
      justify-content: space-between;
    }

    .subrow,
    .requestSubrow {
      padding: 0 34px 0 64px;
    }

    .requestSubrow,
    .bottomRow {
      border-radius: 0 0 8px 8px;
    }
  }

  .rowRight {
    width: 206px;
    display: flex;
    justify-content: space-between;
  }

  .percentage {
    color: $light_gray;
    font-size: 14px;
  }

  .inputContainer {
    display: flex;
    border-bottom: 1px solid $border_gray;
    align-items: center;
    gap: 4px;
  }

  .input {
    border-radius: 0;
    padding: 0;
    width: 130px;
    font-size: 14px;

    :global {
      .ant-input-suffix {
        font-size: 14px;
        color: $light_gray;
      }
    }
  }

  .suffix {
    color: $light_gray;
  }

  .reset {
    color: $light_blue;
    cursor: pointer;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .subrow {
    display: flex;
    justify-content: space-between;
  }

  .sum {
    justify-content: space-between;
    padding: 0 85px 0 32px;
  }
}
