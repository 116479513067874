@import "@/styles/colors.module";

.warning {
  border-radius: 4px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gold;
  background: $gray;
  gap: 8px;
  font-size: 14px;
  color: $light_gray;
}