@import '@/styles/colors.module';

.wrapper {
  display: flex;
  background-color: $white;
  align-items: center;
  height: 68px;
  padding: 0 32px 0 64px;
  justify-content: space-between;
  gap: 25px;

  .amount {
    width: 215px;
    color: $light_gray;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .price {
      white-space: nowrap;
      width: 140px;
    }
  }
}
