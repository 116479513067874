@import '@/styles/colors.module';
.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .nameInput {
    width: 220px;
  }
  .typeSelect {
    height: 40px;
    width: 180px;
  }
  .rangeSelect {
    height: 40px;
    width: 72px;
  }
  .valueInput {
    width: 520px;
  }
  .valueInputShort {
    width: 161px;
  }
  .crossIcon {
    display: flex;
    align-items: center;
    color: $light_gray;
  }
  .deleteIcon {
    cursor: pointer;
    color: $blue;
    &:hover {
      color: $red;
    }
  }
}
