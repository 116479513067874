@import '@/styles/colors.module';

.wrapper {
  display: flex;
  background-color: $white;
  height: 68px;
  border-radius: 8px;
  padding-left: 32px;

  .link {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  .inputContainer {
    display: flex;
    border-bottom: 1px solid $border_gray;
    align-items: center;
    gap: 4px;
  }

  .input {
    border-radius: 0;
    padding: 0;
    width: 120px;
    font-size: 14px;

    :global {
      .ant-input-suffix {
        font-size: 14px;
        color: $light_gray;
      }
    }
  }

  .main {
    width: 728px;
    justify-content: space-between;
    gap: 25px;
    display: flex;
    align-items: center;
    margin-right: 50px;

    .tooltip {
      width: fit-content;
      height: fit-content;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .amount {
    color: $light_gray;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .price {
      white-space: nowrap;
      width: 140px;
    }

    .priceGreen {
      color: $green;
    }
    .priceRed {
      color: $red;
    }

    .sign {
      color: $light_gray;
    }
  }

  .suffix {
    color: $light_gray;
  }

  .reset {
    color: $light_blue;
    cursor: pointer;
  }

  .question {
    z-index: 5;
    color: $light_blue;
  }
}
