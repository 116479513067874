@import '@/styles/colors.module';

.results {
  &Children {
    padding-left: 24px;
    border-left: 1px solid $input_border_gray;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.title {
  font-size: 20px;

  &WithList {
    margin-bottom: 12px;

  }
}

.item {
  color: $light_gray;
  display: grid;
  grid-template-columns: 360px max-content;
  gap: 15px;

  &ParentWithChildren {
    margin-top: 12px;
  }

  &Children {
    grid-template-columns: 200px max-content;
  }
}

.vIcon {
  cursor: pointer;

  &Rotate {
    transform: rotate(180deg);
  }
}
