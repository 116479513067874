@import '@/styles/colors.module';
.procedures {
  :global {
    .ant-spin-nested-loading .ant-spin-container {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 20px;

  .grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 478px 166px 236px 112px;
    padding: 0 32px;
    font-weight: 500;
    font-size: 16px;
  }
  .icon {
    display: flex;
    align-items: center;
    height: 16px;
    width: 16px;
  }
  .questionIcon {
    cursor: help;
    color: $light_gray;
  }
  .crossIcon {
    cursor: pointer;
    color: $blue;
    &:hover {
      color: $red;
    }
  }
}
