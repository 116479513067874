@import '@/styles/colors.module';

.settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px;
  background: $white;
  font-size: 14px;
  font-weight: 500;

  .deleteBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 40px;
    color: $red;
    border-color: $red;

    &:disabled {
      background: transparent;
      color: $light_gray;
      border-color: $light_gray;
    }
  }

  .addBtn,
  .importBtn {
    height: 40px;
    width: 178px;
    display: flex;
    align-items: center;
  }

  .importBtn {
    border: none;
  }
}

.warning {
  border-radius: 4px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid $gold;
  gap: 16px;
  font-size: 14px;

  .warningText {
    height: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $light_gray;
  }

  .more {
    cursor: pointer;
    font-weight: 500;
    color: $light_blue;
    text-decoration: underline dashed;
    text-underline-position: under;

    &:hover {
      color: $blue;
    }
  }

  .split {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    color: $blue;
  }

  .cross {
    color: $light_gray;
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }
}
