@import '@/styles/colors.module';

.wrapper {
  column-gap: 80px;
  row-gap: 5px;
}

.switch {
  height: fit-content;
}

.tabs {
  :global {
    .ant-tabs {
      &-nav {
        margin-bottom: 20px !important;

        &:before {
          border-bottom: none !important;
        }
      }
      &-tab {
        padding-top: 0 !important;
      }
    }
  }
}
