.button {
  display: flex !important;
  align-items: center;
  padding: 0 !important;

  &Add {
    align-self: flex-start;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
}
