@import '@/styles/colors.module';

.wrapper {
  background-color: white;

  .header {
    padding: 46px 64px;
    overflow: hidden;
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .main {
    padding: 32px 54px 32px 32px;
    background-color: $gray;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .upper {
    border-radius: 8px 8px 0 0;
  }

  .inner,
  .innerChild {
    border-radius: 0;
  }

  .inner,
  .lower {
    border-top: 1px solid $border_light_gray;
  }
  .lower {
    border-radius: 0 0 8px 8px;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
