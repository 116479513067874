@import '@/styles/colors.module';

.wrapper {
  margin: 0 30px;

  :global {
    .ant-typography {
      margin-bottom: 0;
    }

    .ant-table-thead .ant-table-cell.ant-table-selection-column {
      padding-left: 27px;
    }

    .ant-table-tbody .ant-table-cell.ant-table-selection-column {
      padding-left: 27px;
    }
  }
}

.table {
  :global {
    .ant-table-thead .ant-table-cell {
      white-space: nowrap;
    }
  }
}

.region {
  max-width: 110px;
}

.name,
.supplier,
.country {
  max-width: 200px;
  width: 200px;
}

.count {
  white-space: nowrap;
}

.smp {
  padding-right: 27px;
}


.phone {
  display: block;
  min-width: 100px;
}

.reestrNumber,
.phone,
.email {
  white-space: nowrap;
  color: $blue;
}

.reestrNumber {
  .ant-tooltip-arrow {
    display: none;
  }
}

.tableFooter {
  padding-left: 24px;
}

.pagination {
  display: flex;
  align-items: center;
}

.okpd2 {
  white-space: nowrap;
}

.row {
  vertical-align: top;
}

.copyLink {
  .ant-typography-copy {
    display: flex;
  }

  width: 180px;

  .copyIcon {
    opacity: 0;
  }

  &:hover {
    .copyIcon {
      opacity: 1;
    }
  }
}

.copyIcon {
  transition: .1s ease-in opacity;
  text-align: center;
  align-content: center;
  align-self: center;
}

.contentPopOver {
  text-align: center;
  width: 300px;
}

.settings {
  margin: 0 32px 16px;
}