@import '@/styles/colors.module';

.wrapper {
  background-color: white;

  .header {
    padding: 46px 64px;
    overflow: hidden;
  }

  .title {
    min-width: 560px;
    max-width: 560px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }

  .main {
    padding: 32px 54px 32px 32px;
    background-color: $border_light_gray;
    height: 100%;
  }

  .tabs {
    display: flex;
    align-items: center;
    height: 64px;
  }

  .tab {
    :global {
      .ant-tabs-tab {
        font-weight: 500;
        color: $light_blue;
        font-size: 16px;
        padding: 0 0 12px 0;
        line-height: 20px;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: black;
      }

      .ant-tabs-nav {
        margin: 0;
        &::before {
          border-bottom: none;
        }
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    color: $white;
    background: $light_blue;
    padding: 5px 16px;
  }

  .hintIcon {
    min-width: 16px !important;
  }
}
